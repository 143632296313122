@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Bold.eot'); /* IE9 Compat Modes */
  src: url('./Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Gotham-Bold.woff') format('woff'),
    /* Modern Browsers */ url('./Gotham-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./Gotham-Bold.svg#svgFontName')
      format('svg'); /* Legacy iOS */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Medium.eot'); /* IE9 Compat Modes */
  src: url('./Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Gotham-Medium.woff') format('woff'),
    /* Modern Browsers */ url('./Gotham-Medium.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./Gotham-Medium.svg#svgFontName')
      format('svg'); /* Legacy iOS */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Light.eot'); /* IE9 Compat Modes */
  src: url('./Gotham-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Gotham-Light.woff') format('woff'),
    /* Modern Browsers */ url('./Gotham-Light.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./Gotham-Light.svg#svgFontName')
      format('svg'); /* Legacy iOS */
  font-weight: 400;
  font-style: normal;
}
